<template>
  <div class="col">
    <div class="row">
      <div class="col-md-6 mb-4">
        <div class="card bg-light border-blue">
          <div class="card-body pt-3 pb-3">
            <div class="text-center">
              <div class="font-weight-bold font-size-24">
                Certificate
              </div>
              <!--              <div v-if="items && account.ca_enabled">-->
              <!--                Expired On: {{ getDate(items.created_on) }}-->
              <!--              </div>-->
              <div v-if="items && account && account.ca_enabled">
                Certificate Total: 0
              </div>
              <router-link
                :to="{ name: 'pay', params: { mode: !mode } }"
              >
                Create Certificate
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card bg-light border-blue">
          <div class="card-body pt-3 pb-3">
            <div class="text-center">
              <div class="font-weight-bold font-size-24">
                Authentication Service
              </div>
              <div v-if="isAllowedAuthentication && dataIsLoaded">
                <div>
                  Expired On: {{ getDate(authenticationService.created_on) }}
                </div>
                <div v-if="authenticationService && account && account.as_enabled">
                  CPS Limit : 0
                </div>
              </div>

              <div class="d-flex justify-content-center flex-wrap mt-2">
                <button
                  v-if="!isAllowedAuthentication && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-danger m-1"
                  :to="{ name: 'pay', params: { type: 'auth' } }"
                  @click="subscribed(account.as_enabled)"
                >
                  Subscribe
                </button>
                <br>
                <button
                  v-if="isAllowedAuthentication && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-primary m-1"
                  :to="{ name: 'pay', params: { type: 'auth', mode: 'extend' } }"
                >
                  Extend Expiry Date
                </button>
                <br>
                <button
                  v-if="isAllowedAuthentication && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-warning m-1"
                  :to="{ name: 'pay', params: { type: 'auth', mode: 'increase' } }"
                >
                  Increase Capacity
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4">
        <div class="card bg-light border-blue">
          <div class="card-body pt-3 pb-3">
            <div class="text-center">
              <div class="font-weight-bold font-size-24">
                Verification Service
              </div>
              <div v-if="isAllowedVerification && dataIsLoaded">
                <div>
                  Expired On: {{ getDate(verificationService.created_on) }}
                </div>
                <div>
                  CPS Limit : 0
                </div>
              </div>

              <div class="d-flex justify-content-center flex-wrap mt-2">
                <button
                  v-if="!isAllowedVerification && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-danger m-1"
                  :to="{ name: 'pay', params: { type: 'verif' } }"
                  @click="subscribed(account.vs_enabled)"
                >
                  Subscribe
                </button>
                <br>
                <button
                  v-if="isAllowedVerification && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-primary m-1"
                  :to="{ name: 'pay', params: { type: 'verif', mode: 'extend' } }"
                >
                  Extend Expiry Date
                </button>
                <br>
                <button
                  v-if="isAllowedVerification && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-warning m-1"
                  :to="{ name: 'pay', params: { type: 'verif', mode: 'increase' } }"
                >
                  Increase Capacity
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="card bg-light border-blue">
          <div class="card-body pt-3 pb-3">
            <div class="text-center">
              <div class="font-weight-bold font-size-24">
                Signing Service
              </div>
              <div v-if="isAllowedSigning && dataIsLoaded">
                <div v-if="signingService && account && account.ss_enabled">
                  Expired On: {{ getDate(signingService.created_on) }}
                </div>
                <div v-if="signingService && account && account.ss_enabled">
                  CPS Limit : 0
                </div>
              </div>
              <div class="d-flex justify-content-center flex-wrap mt-2">
                <button
                  v-if="!isAllowedSigning && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-danger m-1"
                  :to="{ name: 'pay', params: { type: 'sign' } }"
                  @click="subscribed(account.ss_enabled)"
                >
                  Subscribe
                </button>
                <br>
                <button
                  v-if="isAllowedSigning && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-primary m-1"
                  :to="{ name: 'pay', params: { type: 'sign', mode: 'extend' } }"
                >
                  Extend Expiry Date
                </button>
                <br>
                <button
                  v-if="isAllowedSigning && dataIsLoaded"
                  tag="router-link"
                  class="btn btn-warning m-1"
                  :to="{ name: 'pay', params: { type: 'sign', mode: 'increase' } }"
                >
                  Increase Capacity
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import axios from '@/axios'
    import moment from 'moment'

    export default {
        data() {
            return {
                items: null,
                account: null,
                signingService: null,
                verificationService: null,
                authenticationService: null,
                isAllowedAuthentication: false,
                isAllowedVerification: null,
                isAllowedSigning: null,

                dataIsLoaded: false
            }
        },
        async mounted() {
            // await this.loadItems()
        },
      async created() {
        await this.loadItems()
      },
      methods: {
            loadItems() {
                axios
                    .get('/user/me')
                    .then((res) => {
                        // console.log("items", res)
                        this.items = res.data
                        this.account = this.items.service_provider_account
                      if(this.account){
                        this.signingService = this.account.signing_service
                        this.verificationService = this.account.verification_service
                        this.authenticationService = this.account.authentication_service

                        this.dataIsLoaded = true
                        /* authentication */
                        if (this.authenticationService && this.account.as_enabled) {
                          this.isAllowedAuthentication = true
                        } else if (this.authenticationService && !this.account.as_enabled) {
                          this.isAllowedAuthentication = false
                        }

                        /* verification */
                        if (this.verificationService && this.account.vs_enabled) {
                          this.isAllowedVerification = true
                        } else if (this.verificationService && !this.account.vs_enabled) {
                          this.isAllowedVerification = false
                        }

                        /* signing */
                        if (this.signingService && this.account.ss_enabled) {
                          this.isAllowedSigning = true
                        } else if (this.signingService && !this.account.ss_enabled) {
                          this.isAllowedSigning = false
                        }
                      }

                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            subscribed(isEnabled) {
                if (isEnabled) {
                    console.log('subscribed')
                } else {
                    console.log('send post api for showing data')
                }
            },
            getDate(date) {
                return moment(date).format('YYYY/MM/DD')
            },
        }
    }
</script>
<style scoped>
    a {
        text-decoration: underline;
    }

    .card {
        height: 100%;
        margin-bottom: 0 !important;
    }
</style>